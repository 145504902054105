<template>
  <div>
    <el-card style="margin-bottom: 100px">
      <el-form ref="form" :inline="true">
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd">添加字母</el-button>
        </el-form-item>
      </el-form>
      <div class="navs">
        <div class="headers">
          <ul>
            <li
              :class="{ selected: tabs == index }"
              @click="toggletabs(index, item.value)"
              v-for="(item, index) in alphabetTypeList"
              :key="index"
            >
              {{ item.value }}
            </li>
          </ul>
        </div>
      </div>
      <div class="contents">
        <el-table
          :data="alphabetList"
          stripe
          ref="userTable.multipleSelection"
          header-cell-class-name="table-header"
          border
        >
          <template>
            <el-table-column
              label="字母"
              prop="word"
              align="center"
              key="1"
              width="200"
            >
            </el-table-column>
            <el-table-column
              label="罗马音"
              prop="voice"
              align="center"
              key="2"
              width="200"
            >
            </el-table-column>
            <el-table-column
              label="音频"
              align="center"
              prop="voice_file"
              key="3"
            >
            </el-table-column>
          </template>
          <!-- <template v-if="type == '元音'">
            <el-table-column
              label="元音"
              prop="word"
              align="center"
              key="4"
              width="200"
            >
            </el-table-column>
          </template>
          <template v-if="type == '辅音'">
            <el-table-column
              label="辅音"
              prop="word"
              align="center"
              key="5"
              width="200"
            >
            </el-table-column>
          </template>
          <template v-if="type != '字母'">
            <el-table-column
              label="发音技巧"
              align="center"
              prop="note"
              key="6"
            >
            </el-table-column>
            <el-table-column
              label="发音讲解"
              align="center"
              prop="vod_file"
              key="7"
            >
            </el-table-column>
          </template> -->

          <el-table-column label="操作" align="center" width="200">
            <template slot-scope="scope">
              <el-button
                type="primary"
                icon="el-icon-edit"
                circle
                @click="toEdit(scope.row)"
                size="small"
              ></el-button>

              <el-button
                type="success"
                icon="el-icon-video-play"
                circle
                @click="toRadioPlay(scope.row)"
                size="small"
              ></el-button
              ><!--v-if="type == '字母'"-->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>

    <!-- 字母编辑 -->
    <el-dialog
      :visible.sync="editDialog"
      :title="addtitle"
      append-to-body
      width="50%"
      :close-on-click-modal="false"
      @close="editDialogClose"
    >
      <el-form :model="editForm" ref="editForm">
        <template>
          <el-form-item el-form-item label="字母" label-width="100px">
            <el-input
              v-model="editForm.word"
              placeholder="请输入字母"
            ></el-input>
          </el-form-item>
          <el-form-item el-form-item label="罗马音" label-width="100px">
            <el-input
              v-model="editForm.voice"
              placeholder="请输入罗马音"
            ></el-input>
          </el-form-item>
          <el-form-item el-form-item label="音频" label-width="100px">
            <div v-if="audio_url && fileAudioName == ''">
              <audio
                :src="audio_url"
                style="cursor: pointer;outline: none;"
                controls="controls"
                ref="audioPlay"
              ></audio>
            </div>
            <el-button type="primary" @click="selectAudio">{{
              isAdd == "add" ? "上传音频" : "修改音频"
            }}</el-button>
            <span class="file-name">{{ fileAudioName }}</span>
            <el-progress
              :percentage="audioProgress"
              v-if="audioProgress"
            ></el-progress>
            <input
              type="file"
              name="audio"
              id=""
              hidden
              ref="audio"
              @change="selectAudioFiles"
              accept="audio/*"
            />
          </el-form-item>
        </template>
        <!-- <template v-if="this.type == '元音'">
          <el-form-item el-form-item label="元音" label-width="100px">
            <el-input
              v-model="editForm.word"
              placeholder="请输入元音"
            ></el-input>
          </el-form-item>
        </template>
        <template v-if="this.type == '辅音'">
          <el-form-item el-form-item label="辅音" label-width="100px">
            <el-input
              v-model="editForm.word"
              placeholder="请输入辅音 "
            ></el-input>
          </el-form-item>
        </template>
        <template v-if="this.type != '字母'">
          <el-form-item el-form-item label="发音技巧" label-width="100px">
            <el-input
              type="textarea"
              :rows="4"
              v-model="editForm.note"
              placeholder="请输入发音技巧"
            />
          </el-form-item>

          <el-form-item el-form-item label="发音音频" label-width="100px">
            <div v-if="audio_url">
              <audio
                :src="audio_url"
                style="cursor: pointer;outline: none;"
                controls="controls"
                ref="audioPlay"
              ></audio>
            </div>
            <el-button type="primary" @click="selectAudioAlp">{{
              isAdd == "add" ? "上传音频" : "修改音频"
            }}</el-button>
            <span class="file-name">{{ fileAlpAudioName }}</span>
            <el-progress
              :percentage="AlpAudioProgress"
              v-if="AlpAudioProgress"
            ></el-progress>
            <input
              type="file"
              name="audio"
              id=""
              hidden
              ref="audioAlp"
              @change="selectAlpFiles"
              accept="audio/*"
            />
          </el-form-item>

          <el-form-item el-form-item label="发音讲解" label-width="100px">
            <div v-if="editForm.video_url">
              <video
                :src="editForm.video_url"
                style="width: 300px; height: 200px; cursor: pointer"
                autoplay
                v-if="editForm.video_url"
                @click="selectVideo"
                ref="videoPlay"
              ></video>
            </div>
            <el-button type="primary" @click="selectVideo"
              >选择视频文件</el-button
            ><span class="file-name">{{ fileVideoName }}</span>
            <el-progress
              :percentage="videoProgress"
              v-if="videoProgress"
            ></el-progress>
            <input
              type="file"
              name="video"
              id=""
              hidden
              ref="video"
              @change="selectVideoFiles"
              accept="video/*"
            />
          </el-form-item>
          <el-form-item
            el-form-item
            label="发音示例"
            label-width="100px"
            v-if="editForm.examples"
          >
            <el-input
              type="textarea"
              :rows="1"
              v-model="editForm.examples[0].sentence"
              placeholder="请输入发音示例"
            />
          </el-form-item>
          <el-form-item
            el-form-item
            label="示例翻译"
            label-width="100px"
            v-if="editForm.examples"
          >
            <el-input
              type="textarea"
              :rows="1"
              v-model="editForm.examples[0].translate"
              placeholder="请输入示例翻译"
            />
          </el-form-item>
          <el-form-item
            el-form-item
            label="示例音频"
            label-width="100px"
            v-if="editForm.examples"
          >
            <div v-if="editForm.audio_url && this.isAdd == 'edit'">
              <audio
                :src="editForm.audio_url"
                style="cursor: pointer;outline: none;"
                controls="controls"
                ref="audioPlay"
              ></audio>
            </div>
            <el-button type="primary" @click="selectAudio">{{
              isAdd == "add" ? "上传示例音频" : "修改示例音频"
            }}</el-button>
            <span class="file-name">{{ fileAudioName }}</span>
            <el-progress
              :percentage="audioProgress"
              v-if="audioProgress"
            ></el-progress>
            <input
              type="file"
              name="audio"
              id=""
              hidden
              ref="audio"
              @change="selectAudioFiles"
              accept="audio/*"
            />
          </el-form-item> -->
        <!-- </template> -->
        <el-form-item label-width="40%">
          <el-button type="primary" v-if="isAdd == 'add'" @click="addNow"
            >立即添加</el-button
          >
          <el-button type="primary" v-else-if="isAdd == 'edit'" @click="editNow"
            >立即修改</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 点击视频播放 -->
    <el-dialog
      :visible.sync="videoDialog"
      title="五十音视频"
      append-to-body
      width="50%"
      :close-on-click-modal="false"
      @close="videoDialogClose"
    >
      <div>
        <video
          :src="videoDetailsSrc"
          style="width: 100%; height: 520px; cursor: pointer"
          autoplay
          controls
          ref="videoDetails"
        ></video>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import {
  addLetter,
  updateLetter,
  getLetterList,
  getLetterDetail,
  getReadCosToken
} from "@/api/French/french_alphabet";
import { warningDialog } from "@/utils/dialog";
var COS = require("cos-js-sdk-v5");
import { log } from "util";

export default {
  data() {
    return {
      alphabetTypeList: [
        // { id: 1, value: "字母" },
        { id: 2, value: "元音" },
        { id: 3, value: "辅音" }
      ],
      tabs: 0,
      type: "元音",
      alphabetList: [],
      editDialog: false,
      // editForm: {},
      videoProgress: 0,
      audioProgress: 0,
      AlpAudioProgress: 0,
      fileVideoName: "",
      isVoiceless: true,
      videoDialog: false,
      videoDetailsSrc: "",
      addtitle: "",
      isAdd: "add",
      editForm: {
        word: "",
        voice: "",
        voice_file: "",
        type: 2,
        mold_type: 4,
        // note: "", //发音要领
        // vod_file: "",
        // video_url: "",
        audio_url: "",
        examples: [
          {
            sentence: "", // 示例单词
            translate: "", // 翻译
            read: "" // 音频文件
          }
        ]
      },
      audio_url: "",
      fileAudioName: "",
      fileAlpAudioName: "",
      fileVideoName: ""
    };
  },
  created() {
    this.getAlphabetList({ type: 2, size: 30, mold: 4 });
  },

  methods: {
    // tab栏切换
    toggletabs(index, type) {
      this.tabs = index;
      this.type = type;
      if (this.type == "元音") {
        this.editForm.type = 2;
      } else if (this.type == "辅音") {
        this.editForm.type = 3;
      }
      this.getAlphabetList({ type: this.editForm.type, size: 30, mold: 4 });
    },

    //添加字母
    toAdd() {
      this.editDialog = true;
      this.addtitle = "添加字母";
      this.isAdd = "add";
      this.editForm.word = "";
      this.editForm.voice = "";
      this.editForm.voice_file = "";
      // this.editForm.note = "";
      // this.editForm.vod_file = "";
      this.audioProgress = 0;
      this.videoProgress = 0;
      this.AlpAudioProgress = 0;
      this.fileAlpAudioName = "";
      this.editForm.audio_url = "";
      this.editForm.examples = [
        {
          sentence: "", // 示例单词
          translate: "", // 翻译
          read: "" // 音频文件
        }
      ];
    },
    //立即添加
    addNow() {
      console.log(this.editForm);
      if (this.editForm.type == 2 && this.editForm.word == "") {
        this.$message.error("元音不能为空！");
        return;
      } else if (this.editForm.type == 3 && this.editForm.word == "") {
        this.$message.error("辅音不能为空！");
        return;
      } else if (this.editForm.voice == "") {
        this.$message.error("罗马音不能为空！");
        return;
      } else if (this.editForm.voice_file == "") {
        this.$message.error("音频不能为空！");
        return;
      }
      console.log(this.editForm);
      addLetter(this.editForm).then(res => {
        console.log(res);
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加成功!");
        this.editDialog = false;
        this.getAlphabetList({ type: this.editForm.type, size: 30, mold: 4 });
        this.editForm = {
          word: "",
          voice: "",
          voice_file: "",
          type: "",
          mold_type: 4,
          // note: "", //发音要领
          // vod_file: "",
          examples: [
            {
              sentence: "", // 示例单词
              translate: "", // 翻译
              read: "" // 音频文件
            }
          ]
        };
        if (this.type == "元音") {
          this.editForm.type = 2;
        } else if (this.type == "辅音") {
          this.editForm.type = 3;
        }
      });
    },
    //获取字母列表
    getAlphabetList(type) {
      getLetterList(type).then(res => {
        console.log(res);
        this.alphabetList = res.data;
      });
    },
    // 视频播放弹框关闭事件
    videoDialogClose() {
      if (this.$refs.videoDetails !== undefined) {
        this.$refs.videoDetails.pause();
      }
    },

    // 点击操作栏的视频播放按钮
    toRadioPlay(item) {
      if (item.radio == "" || item.voice_file == "") {
        this.$message.error("暂无音频,亲请上传音频后重试~");
        return;
      }
      // this.videoDetailsSrc = item.video_url;
      // this.videoDialog = true;
      // console.log(item);
      let audio_play = new Audio(item.voice_file);
      audio_play.play();
    },

    // 点击编辑图标
    toEdit(item) {
      this.addtitle = "编辑字母";
      this.isAdd = "edit";
      this.audioProgress = 0;
      // this.videoProgress = 0;
      // this.AlpAudioProgress = 0;
      this.fileAlpAudioName = "";
      this.fileAudioName = "";
      this.fileVideoName = "";
      console.log(item);
      getLetterDetail({ id: item.id }).then(res => {
        console.log(res);
        if (res.data.type == 1) {
          this.editForm = res.data;
          this.audio_url = item.voice_file;
          this.editForm.voice_file = this.editForm.voice_file
            .split("?")[0]
            .replace("https://file.qiaxueedu.com/", "");
          // console.log(this.editForm);
        } else if (res.data.type == 2 || res.data.type == 3) {
          let data = JSON.parse(res.data.example.replace(/[\\]/g, ""));
          this.editForm.word = res.data.word;
          this.editForm.voice = res.data.voice;
          this.editForm.vod_file = res.data.vod_file
            .split("?")[0]
            .replace("https://file.qiaxueedu.com/", "");
          this.editForm.video_url = res.data.vod_file;
          this.editForm.voice_file = res.data.voice_file
            .split("?")[0]
            .replace("https://file.qiaxueedu.com/", "");
          this.audio_url = res.data.voice_file;
          this.editForm.id = res.data.id;
          this.editForm.type = res.data.type;
          this.editForm.examples = data;
          this.editForm.audio_url = data[0].read;
          // console.log(this.editForm);
          // console.log(this.audio_url);
          this.editForm.examples[0].read = this.editForm.examples[0].read
            .split("?")[0]
            .replace("https://file.qiaxueedu.com/", "");
          // console.log(this.editForm.audio_url);
          this.editForm.examples[0].translate = JSON.parse(
            res.data.example
          )[0].translate;
          this.editForm.examples[0].translate = unescape(
            this.editForm.examples[0].translate.replace(/u/g, "%u")
          );
          this.editForm.examples[0].sentence = JSON.parse(
            res.data.example
          )[0].sentence;
          this.editForm.examples[0].sentence = unescape(
            this.editForm.examples[0].sentence.replace(/u/g, "%u")
          );
          this.editForm.examples[0].sentence = unescape(
            this.editForm.examples[0].sentence.replace(/%/g, "")
          );
        }
      });
      this.editDialog = true;
    },

    // 点击立即修改
    editNow() {
      console.log(this.editForm);
      if (this.editForm.type == 2 && this.editForm.word == "") {
        this.$message.error("元音不能为空！");
        return;
      } else if (this.editForm.type == 3 && this.editForm.word == "") {
        this.$message.error("辅音不能为空！");
        return;
      } else if (this.editForm.voice == "") {
        this.$message.error("罗马音不能为空！");
        return;
      } else if (this.editForm.voice_file == "") {
        this.$message.error("音频不能为空！");
        return;
      }
      updateLetter(this.editForm).then(res => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          // this.progress = 0;
          // this.editForm.vod_file = "";
          return;
        }
        this.$message.success("修改成功!");
        this.editDialog = false;
        // this.progress = 0;
        this.editForm.vod_file = "";
        this.getAlphabetList({ type: this.editForm.type, size: 30, mold: 4 });
      });
    },

    // 修改弹窗关闭触发
    editDialogClose() {
      if (this.$refs.videoPlay !== undefined) {
        this.$refs.videoPlay.pause();
      }
      this.fileAudioName = "";
      this.fileVideoName = "";
      this.audio_url = "";
      this.editForm.video_url = "";
    },

    // 点击选择视频文件按钮
    selectVideo() {
      this.$refs.video.click();
    },

    // 选择了视频文件
    selectVideoFiles() {
      let file = this.$refs.video.files[0];
      if (this.$refs.video.files.length == 0) {
        this.editForm.vod_file = "";
        return;
      }
      this.editForm.vod_file = file.name;
      this.fileVideoName = file.name;
      // if (file.size / 1024 / 1024 > 30) {
      //   this.$message.error("音频不能大于 30M ");
      //   return;
      // }
      getReadCosToken({ suffix: file.name }).then(res => {
        this.CosObj = res.data;
        var that = this;
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: that.CosObj.Bucket,
            Region: that.CosObj.Region,
            Key: that.CosObj.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function(progressData) {
              that.videoProgress = progressData.percent * 100;
              that.editForm.vod_file = that.CosObj.key;
              that.editForm.video_url = "";
            }
          },
          function(err, data) {
            console.log(err || data);
          }
        );
      });
    },
    // 点击选择字母音频文件按钮或者元音、辅音示例音频
    selectAudio() {
      console.log(this.$refs.audio);
      this.$refs.audio.click();
    },
    // 选中音频
    selectAudioFiles(e) {
      let file = this.$refs.audio.files[0];
      this.fileAudioName = file.name;
      // if (this.editForm.type == 1) {
      //   this.editForm.voice_file = file.name;
      // } else {
      //   this.editForm.examples[0].read = file.name;
      // }
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isSize) {
        this.$message.error("上传音频不能超过 30MB!");
        return;
      }

      let that = this;
      getReadCosToken({ suffix: file.name }).then(res => {
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function(progressData) {
              that.audioProgress = progressData.percent * 100;
            }
          },
          function(err, data) {
            if (data) {
              console.log(that.type);
              that.editForm.voice_file = res.data.key;
              // if (that.type == "字母") {
              //   that.editForm.voice_file = res.data.key;
              // } else {
              //   that.editForm.examples[0].read = res.data.key;
              // }
            }
            e.target.value = "";
          }
        );
      });
    },

    // 点击选择元音、辅音音频文件按钮
    selectAudioAlp() {
      console.log(this.$refs.audio);
      this.$refs.audioAlp.click();
    },
    // 选中音频
    selectAlpFiles(e) {
      let file = this.$refs.audioAlp.files[0];
      this.fileAlpAudioName = file.name;
      // if (this.editForm.type == 1) {
      //   this.editForm.voice_file = file.name;
      // } else {
      //   this.editForm.examples[0].read = file.name;
      // }
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isSize) {
        this.$message.error("上传音频不能超过 30MB!");
        return;
      }

      let that = this;
      getReadCosToken({ suffix: file.name }).then(res => {
        var cos = new COS({
          getAuthorization: function(options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId
            });
          }
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function(progressData) {
              that.AlpAudioProgress = progressData.percent * 100;
            }
          },
          function(err, data) {
            if (data) {
              console.log(that.type);
              that.editForm.voice_file = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    }
  }
};
</script>

<style scoped lang="less">
.avatar {
  z-index: 999;
}

* {
  box-sizing: border-box;
}
.generate {
  float: right;
}
li {
  list-style: none;
  cursor: pointer;
}
.navs {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #323232;
  padding: 0 20px;
  .headers {
    border-bottom: 1px solid #eeeeee;
    height: 120px;
    line-height: 50px;
    width: 100%;
    height: 50px;
    ul {
      width: 100%;
      li {
        height: 50px;
        float: left;
        margin-right: 60px;
      }
    }
    .selected {
      color: #38a28a;
      border-bottom: 1px solid #38a28a;
    }
  }
}
.contents {
  margin-top: 10px;
}
</style>
